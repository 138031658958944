import { Link } from "react-router-dom";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function KitchenRenovationsRankwick() {
  const areasWeServe = [
    {
      title: "woollahra",
      href: "/kitchens-renovations-woollahra",
    },
    // {
    //   title: "randwick",
    //   href: "/kitchen-renovations-randwick",
    // },
  ];
  const craftsmenData = [
    {
      title: "Unwavering Dedication to Quality",
      desc: "We are uncompromising in our pursuit of quality. Every project utilises only the finest materials, meticulously crafted with joinery techniques that stand the test of time. This unwavering dedication ensures your custom cabinets are not just stunning additions to your home but incredibly durable pieces built to last a lifetime.",
    },
    {
      title: "Collaborative Design Process",
      desc: "We believe your vision is the foundation for a perfect outcome. Our experienced cabinet makers across Mona Vale will work hand-in-hand with you to understand your unique design goals, space limitations and personal preferences. We translate your ideas into detailed plans, ensuring the final product seamlessly complements your existing décor and reflects your style.",
    },
    {
      title: "Budget and Timeline",
      desc: "Transparency and clear communication are at the heart of our process. We will work diligently to stay within your established budget and adhere to the agreed-upon timeline. Throughout the project, we'll keep you informed of progress and address any questions you may have, ensuring a smooth and stress-free experience.",
    },
    {
      title: "Exceptional Customer Service",
      desc: "Our dedicated team prioritises your satisfaction. We are readily available to answer your questions, address any concerns you may have and ensure you're completely satisfied with the entire process. From initial consultation to final installation, exceptional customer service is our promise to you.",
    },
    {
      title: "A Range of Joinery Solutions",
      desc: "While custom cabinetry is our specialty, our expertise extends far beyond kitchens. We offer a comprehensive suite of joinery services, including wardrobes, vanities, laundries, luxury TV units and living area solutions. This allows us to create a cohesive and stylish look throughout your entire Mona Vale home, ensuring a unified aesthetic that reflects your taste and elevates your living space.",
    },
  ];

  return (
    <section className="zeal-components">
      <section
        style={{ paddingTop: "15rem", paddingBottom: "5rem" }}
        className="container min-vh-100 d-grid justify-content-center"
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch  text-center text-lg-start">
            <h1 className="display-6 font-miracle text-white ">
              Reimagine Your Heart Of The Home: Kitchen Renovations Around
              Randwick
            </h1>
            <p className="font-proximanova-light text-white zeal-digital">
              The kitchen is more than just a place to cook; it's the heart of
              the home. It's where families get together, enjoy meals and create
              memories. However, a dysfunctional or outdated kitchen can quickly
              hinder these experiences. However, our kitchen renovations in
              Randwick can breathe new life into your space, creating an area
              that's aesthetically pleasing and ideally suited to your
              lifestyle. Explore a range of kitchen renovations at Topcab
              Kitchens & Joinery and start your project today.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26487.0982030416!2d151.21954343708404!3d-33.91830067211787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b21cf651e6ab%3A0x5017d681632c800!2sRandwick%20NSW%202031%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012571366!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="suburb-map"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
                border: 0,
                marginLeft: "auto",
              }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="container text-white font-proximanova-light zeal-digital">
        <h2 className="font-miracle mb-5 display-6">
          What Makes Topcab Kitchens & Joinery The Most Trusted Partner For
          Kitchen Renovations Near Randwick?
        </h2>
        <p>
          Looking for a reliable and experienced partner to bring your dream
          kitchen to life? Look no further than Topcab Kitchens & Joinery. We
          are a leading provider of kitchen renovations around Randwick,
          renowned for our:
        </p>
        <strong>Exceptional Craftsmanship </strong>{" "}
        <p>
          Our joiners are not just skilled – they're passionate artisans. They
          meticulously handcraft each kitchen with precision joinery and
          high-quality finishes, ensuring a result that transcends functionality
          and becomes a true focal point in your home.
        </p>
        <strong>Unwavering Dedication to Quality</strong>{" "}
        <p>
          We source only the finest, most durable materials – from solid timber
          to cutting-edge hardware. Combined with our time-tested construction
          methods, this unwavering dedication to quality ensures your cooking
          space endures for years to come.
        </p>
        <strong> Bespoke Design Solutions</strong>{" "}
        <p>
          We believe your kitchen should reflect your unique style and
          personality. Through collaborative consultations, we'll translate your
          vision, preferences and lifestyle needs into a bespoke design that
          maximises functionality and aesthetics within your cooking space.
        </p>
        <strong> Streamlined Renovation Process </strong>{" "}
        <p>
          Our experienced team acts as your single point of contact, handling
          everything from initial design consultations and material selection to
          expert installation in your home. We keep you informed throughout your
          kitchen renovations in Randwick, ensuring a smooth and stress-free
          renovation experience.
        </p>
        <strong> Unmatched Customer Service </strong>{" "}
        <p>
          We prioritise open communication and transparency throughout your
          kitchen renovations around Randwick. Your satisfaction is our ultimate
          goal and we're dedicated to exceeding your expectations at every step
          of the way.
        </p>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="order-0 d-grid align-items-center justify-content-center">
            <img
              src="/zeal-digital/joinery.png"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
          <div
            className="order-1 d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start"
            style={{ fontSize: "14px" }}
          >
            <h2 className="font-miracle mb-3">
              The Difference A Kitchen Renovation Makes
            </h2>
            <ul className="font-proximanova-light d-flex flex-column gap-0 text-start">
              <li>
                <strong>Enhanced Functionality</strong>: A well-designed cooking space optimises
                your workflow. Improved layouts, strategically-placed appliances
                and ample storage solutions ensure a more streamlined cooking
                experience.
              </li>
              <li>
                <strong>Elevated Aesthetics</strong>: Modernise your space with a kitchen
                renovation from <Link to="/">Topcab Kitchens & Joinery</Link> that reflects your
                taste. From sleek contemporary designs to timeless classic
                styles, a renovated <Link to="/luxury-kitchens">luxury kitchen</Link> becomes a focal point of your
                home.
              </li>
              <li>
                <strong>Increased Value</strong>: Kitchen renovations are a wise investment,
                adding significant value to your property. A modern and
                functional kitchen is highly desirable to potential buyers or
                renters.
              </li>
              <li>
                <strong>Improved Quality of Life</strong>: Enjoy the pleasure of cooking and
                entertaining in a space that inspires you. A well-designed
                kitchen can enhance your daily life and create a more enjoyable
                home environment.
              </li>
            </ul>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
        </div>
      </section>
      <section className="text-white container zeal-digital uni-padding-y">
        <div className="text-center">
          <h2 className="font-miracle display-5 mb-3">
            What Makes Topcab Kitchens & Joinery Different In Randwick?
          </h2>
          <p className="font-proximanova-light">
            Here's what sets us apart from other kitchen renovation companies in
            Randwick:
          </p>
        </div>
        <ul className="font-proximanova-light">
          <li>
            <strong>Extensive Experience</strong>: Since 2020, we've carved a niche for
            excellence in Randwick, transforming many kitchens, and enhancing
            the cooking experience. Our expertise ensures we can handle any
            project, regardless of size or complexity, with the skills you
            deserve.
          </li>
          <li>
            <strong>Diverse Portfolio</strong>: Our comprehensive portfolio showcases our
            versatility in kitchen design. We can craft modern masterpieces
            featuring sleek lines and integrated appliances or timeless classics
            that exude warmth and character. No matter your vision or budget, we
            possess the expertise to bring your dream kitchen to life in your
            home.
          </li>
          <li>
            <strong>Dedication to Service</strong>: We recognise that kitchen renovations near
            Randwick is a significant investment and we treat it with the
            respect it deserves. Our unwavering dedication to service extends
            far beyond the design and installation phases. We provide
            comprehensive after-sales support to ensure your Randwick kitchen
            continues to function flawlessly for years to come, offering you
            peace of mind and a kitchen built to last.
          </li>
          <li>
            <strong>Dedication to Innovation</strong>: At Topcab Kitchens & Joinery, we're
            passionate about pushing boundaries and staying ahead of the curve.
            We actively research the latest trends and technologies in kitchen
            design and{" "}
            <Link to="https://topcabjoinery.com.au/detailed-joinery">
              detailed joinery
            </Link>
            , incorporating innovative solutions like smart appliances,
            space-saving storage and energy-efficient features to create a
            kitchen that's as functional and beautiful as it is cutting-edge in
            your home.
          </li>
        </ul>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start">
            <h2 className="display-6 font-miracle">
              Transform Your Kitchen Today: Contact Topcab Kitchens & Joinery
            </h2>
            <p className="font-proximanova-light zeal-digital">
              Are you ready to create a cooking space you'll love? Contact
              Topcab Kitchens & Joinery today by calling{" "}
              <a href="tel:+610296100019">+61 02 96 100 019</a> or emailing{" "}
              <a href="mailto:info@topcabjoinery.com.au">
                info@topcabjoinery.com.au
              </a>{" "}
              for a free consultation. Our team of experts will discuss your
              vision, assess your space and guide you through the entire kitchen
              renovations process in Randwick or in{" "}
              <Link to="https://topcabjoinery.com.au/kitchens-renovations-woollahra">
                Woollahra
              </Link>
              . Let us help you transform your kitchen into a beautiful,
              functional and inspiring heart of your home.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <img
              src="/zeal-digital/reach-us-2.png"
              className="bg-dark rounded-3 ms-auto object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
      <section
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        className="container text-white font-proximanova-light"
      >
        <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
          Area we serve
        </h2>
        <div className="d-grid d-lg-flex flex-wrap gap-4 justify-content-lg-center">
          {areasWeServe?.map((elem) => (
            <Link
              to={elem.href}
              className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
            >
              {elem.title}
            </Link>
          ))}
        </div>
      </section>
      <Form />
    </section>
  );
}
