import { Link } from "react-router-dom";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function WardrobesWetherillPark() {
  const areasWeServe = [
    { title: "maroubra", href: "/wardrobes-maroubra" },
    // {
    //   title: "wetherill park",
    //   href: "/wardrobes-wetherill-park",
    // },
    { title: "bellevue hill", href: "/wardrobes-bellevue-hill" },
    { title: "coogee", href: "/wardrobes-coogee" },
    { title: "rose bay", href: "/wardrobes-rose-bay" },
  ];

  const craftsmenData = [
    {
      title: "Quality Materials",
      desc: "We use only the finest materials to ensure that our wardrobes are not only beautiful but also durable and long-lasting.",
    },
    {
      title: "Innovative Designs",
      desc: "Our designs range from traditional to contemporary, catering to all styles and tastes. We stay up-to-date with the latest trends to provide our clients with modern and stylish options.",
    },
    {
      title: "Expert Craftsmanship",
      desc: "Our team consists of highly-skilled professionals who are experts in their field, ensuring every wardrobe we create is of the highest standard.",
    },
    {
      title: "Personalised Service",
      desc: "We work closely with our clients to understand their needs and preferences, offering tailored solutions that fit their unique requirements.",
    },
    {
      title: "Attention to Detail",
      desc: "We pride ourselves on our meticulous attention to detail, ensuring that every aspect of your wardrobe is perfect.",
    },
    {
      title: "Competitive Pricing",
      desc: "Despite our high standards of quality, we offer competitive pricing to ensure our services are accessible to a wide range of clients.",
    },
    {
      title: "Timely Delivery",
      desc: "We understand the importance of timely delivery and strive to complete every project within the agreed time frame without compromising on quality.",
    },
    {
      title: "Comprehensive Service",
      desc: " From design consultation to installation, we provide a full range of services to ensure a seamless and stress-free experience for our clients.",
    },
  ];

  return (
    <section className="zeal-components">
      <section
        style={{ paddingTop: "15rem", paddingBottom: "10rem" }}
        className="container min-vh-100 d-grid justify-content-center "
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch  text-center text-lg-start">
            <h1 className="display-6 font-miracle text-white ">
              Finest Custom Wardrobes Near Wetherill Park: Topcab Kitchens &
              Joinery
            </h1>
            <p className="font-proximanova-light text-white zeal-digital">
              Wardrobes are an essential component of any home, providing both
              functionality and style. They offer a practical solution for
              storage while enhancing the aesthetic appeal of your rooms. Custom
              wardrobes, in particular, provide a unique advantage as they are
              tailored to fit your specific needs and preferences.{" "}
              <Link to="/">Topcab Kitchens & Joinery</Link> stands out as the
              premier provider of custom wardrobes around Wetherill Park and we
              are known for our exceptional craftsmanship and attention to
              detail.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26510.812698470567!2d150.87781373679115!3d-33.841937842100066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b129705bb6b75d7%3A0x5017d681632cfe0!2sWetherill%20Park%20NSW%202164%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012722795!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="suburb-map"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
                border: 0,
                marginLeft: "auto",
              }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="container text-white font-proximanova-light zeal-digital">
        <h2 className="font-miracle mb-5 display-6">
          Why Select Custom-Made Wardrobes In Wetherill Park?
        </h2>
        <p>
          Custom-made wardrobes offer unparalleled benefits compared to
          off-the-shelf options. They are designed to maximise your space,
          whether you need more hanging room, additional shelves or specialised
          compartments for accessories. Here are some key features that make our
          custom wardrobes so special:
        </p>
        <h3>Personalisation </h3>{" "}
        <p>
          Every element of a custom wardrobe can be tailored to your
          preferences, from the type of wood to the colour and finish. This
          ensures that your wardrobe complements the existing decor of your
          home.
        </p>
        <h3>Space Optimisation </h3>{" "}
        <p>
          Custom wardrobes are designed to fit perfectly in your space, making
          use of every inch of the available area. This is especially useful in
          rooms with awkward layouts or limited space.
        </p>
        <h3 className="h3"> Enhanced Functionality</h3>{" "}
        <p>
          With custom wardrobes, you can include features that cater
          specifically to your needs, such as pull-out drawers, shoe racks and
          jewellery organisers.
        </p>
        <h3 className="h3"> Quality Materials </h3>{" "}
        <p>
          Custom wardrobes are typically made from high-quality materials,
          ensuring durability and longevity.
        </p>
        <h3 className="h3"> Aesthetic Appeal </h3>{" "}
        <p>
          The bespoke design of custom wardrobes adds a touch of elegance and
          sophistication to your home, making it a standout feature in any room.
        </p>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="order-0 d-grid align-items-center justify-content-center">
            <img
              src="/zeal-digital/maroubra-wardrobes-1.jpg"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
          <div
            className="order-1 d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start"
            style={{ fontSize: "14px" }}
          >
            <h2 className="font-miracle mb-3">
              Our Process: How We Create Your Perfect Wardrobes
            </h2>
            <ul className="font-proximanova-light d-flex flex-column gap-2">
              <li>
                Initial Consultation: We begin with a full consultation to
                better understand your joinery requirements, preferences and the
                characteristics of your space.
              </li>
              <li>
                Design & Planning: Our designers develop a precise plan and 3D
                model of your personalised wardrobe that includes all of your
                specifications.
              </li>
              <li>
                Material Selection: We assist you in selecting the materials
                that best suit your style and budget.
              </li>
              <li>
                Crafting and Assembly: Our talented craftsmen bring the design
                to life, ensuring that every detail is done perfectly.
              </li>
              <li>
                Installation: Our team of professionals will install the
                wardrobe in your home, assuring a flawless fit and finish.
              </li>
              <li>
                Final Inspection: We do a final inspection to confirm that our
                project meets our high standards and that you are completely
                satisfied.
              </li>
            </ul>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
        </div>
      </section>

      <section className="text-white container zeal-digital uni-padding-y">
        <div className="text-center pb-5">
          <h2 className="font-miracle display-5 mb-3">
            Your Ideal Provider For Wardrobes In Wetherill Park
          </h2>
          <p className="font-proximanova-light">
            Topcab Kitchens & Joinery is renowned for crafting exquisite
            wardrobes in Wetherill Park. Our team of skilled artisans and
            designers is dedicated to creating custom storage solutions that
            perfectly meet your needs and exceed your expectations. With over 20
            years of experience in the industry, we have built a reputation for
            delivering top-notch quality and exceptional customer service.
          </p>
          <p className="font-proximanova-light">
            Topcab Kitchens & Joinery is dedicated to providing the best
            wardrobes in Wetherill Park and in the suburbs of{" "}
            <Link to="https://topcabjoinery.com.au/wardrobes-bellevue-hill">
              Bellevue Hill
            </Link>{" "}
            and{" "}
            <Link to="https://topcabjoinery.com.au/wardrobes-rose-bay">
              Rose Bay
            </Link>
            . Here&apos;s what sets us apart:
          </p>
        </div>
        <ul>
          {craftsmenData.map((elem) => {
            return (
              <li key={elem.title}>
                <h5> {elem.title} </h5> {elem.desc}
              </li>
            );
          })}
        </ul>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start">
            <h2 className="display-6 font-miracle">
              Get Your Bespoke Wardrobe Near Wetherill Park Today!
            </h2>
            <p>
              Ready to transform your home with a custom wardrobe? Trust Topcab
              Kitchens & Joinery, the leading provider of wardrobes in Wetherill
              Park, to deliver exceptional quality and service. Our dedication
              to excellence and customer satisfaction makes us the best choice
              for all your wardrobe needs.
            </p>
            <p className="font-proximanova-light zeal-digital">
              Contact us today at{" "}
              <a href="tel:+610296100019">+61 02 96 100 019</a> or email at{" "}
              <a href="mailto:info@topcabjoinery.com.au">
                info@topcabjoinery.com.au
              </a>{" "}
              to schedule your consultation and begin the journey towards a
              beautifully-organised and stylish space. Let Topcab Kitchens &
              Joinery create the perfect wardrobe for you, combining
              functionality, quality and elegance in every design.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <img
              src="/zeal-digital/wardrobe-rose-bay-2.jpg"
              className="bg-dark rounded-3 ms-auto object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
      <section
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        className="container text-white font-proximanova-light"
      >
        <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
          Areas we serve
        </h2>
        <div className="d-grid d-lg-flex flex-wrap gap-4 justify-content-lg-center">
          {areasWeServe?.map((elem) => (
            <Link
              to={elem.href}
              className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
            >
              {elem.title}
            </Link>
          ))}
        </div>
      </section>
      <Form />
    </section>
  );
}
