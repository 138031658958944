import { Link } from "react-router-dom";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function WardrobesBellevueHill() {
  const areasWeServe = [
    { title: "maroubra", href: "/wardrobes-maroubra" },
    {
      title: "wetherill park",
      href: "/wardrobes-wetherill-park",
    },
    // { title: "bellevue hill", href: "/wardrobes-bellevue-hill" },
    { title: "coogee", href: "/wardrobes-coogee" },
    { title: "rose bay", href: "/wardrobes-rose-bay" },
  ];

  const craftsmenData = [
    {
      title: "Expert Design Consultation",
      desc: "Collaborate effortlessly with our skilled designers, who will work with you to bring your wardrobe vision to life, ensuring every detail meets your expectations.",
    },
    {
      title: "Craftsmanship Excellence",
      desc: "Our experienced artisans meticulously handcraft each wardrobe, combining traditional techniques with modern precision to deliver superior quality.",
    },
    {
      title: "Customer Satisfaction Ensured",
      desc: "We are dedicated to exceeding your satisfaction by delivering exceptional service and top-notch products that enhance your home.",
    },
    {
      title: "Competitive Pricing",
      desc: "Enjoy competitive pricing without compromising on quality, making luxury wardrobes accessible for every budget.",
    },
  ];

  return (
    <section className="zeal-components">
      <section
        style={{ paddingTop: "15rem", paddingBottom: "10rem" }}
        className="container min-vh-100 d-grid justify-content-center "
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch  text-center text-lg-start">
            <h1 className="display-6 font-miracle text-white ">
              Premium Custom-Made Wardrobes In Bellevue Hill From Topcab
              Kitchens & Joinery
            </h1>
            <p className="font-proximanova-light text-white zeal-digital">
              Are you looking to upgrade the aesthetic appeal of your home? The
              one aspect that can make a big difference is your wardrobe. When
              you revamp your wardrobe, you are ensured to enhance the overall
              aesthetic of your home automatically. Wardrobes are highly
              functional; you need expert assistance to make the most of your
              living space. Wardrobes not only add to expanding the space in
              your home but also help you free up unnecessary clutter.
            </p>
            <p className="font-proximanova-light text-white zeal-digital">
              Custom-built wardrobes also reflect your unique taste. When it
              comes to custom wardrobes in Bellevue Hill{" "}
              <Link to="/">Topcab Kitchens & Joinery</Link> offers exceptional
              craftsmanship that meets bespoke design. We specialise in custom
              wardrobes around Bellevue Hill that are tailored to your unique
              style and space requirements. We stand as a premier provider of
              high-quality carpentry and{" "}
              <Link to="/detailed-joinery">detailed joinery</Link> solutions.
            </p>

            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13249.551394690074!2d151.24602479293026!3d-33.87966375704732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12adc071878945%3A0x5017d681632a950!2sBellevue%20Hill%20NSW%202023%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012609966!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="suburb-map"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
                border: 0,
                marginLeft: "auto",
              }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>

      <section className="text-white container zeal-digital uni-padding-y">
        <div className="text-center pb-5 container">
          <h2 className="font-miracle display-5 mb-3">
            Discover Our Custom Wardrobe Expertise
          </h2>
          <p className="font-proximanova-light">
            At Topcab Kitchens & Joinery, we understand that your home is a
            reflection of your lifestyle and your unique personality. Our
            dedicated team of expert craftsmen and designers can help you bring
            your custom wardrobe vision to life. The team will make sure that
            your wardrobe is not just good-looking but maximises your storage
            space.
          </p>
        </div>
      </section>

      <section className="container text-white font-proximanova-light zeal-digital">
        <h2 className="font-miracle mb-5 display-6 text-center">
          Quality Materials And Features
        </h2>
        <p className="font-proximanova-light">
          At Topcab Kitchens & Joinery, our priority is to use only the highest
          quality premium materials to make sure your wardrobes last for years.
        </p>
        <h3>Select Timber </h3>{" "}
        <p className="font-proximanova-light">
          Our dedication to quality is reflected in our use of the finest timber
          to make wardrobes. Each wardrobe is crafted from carefully-selected
          timber known for its strength and natural aesthetics, ensuring
          longevity and visual appeal.
        </p>
        <h3>Precision Hardware</h3>{" "}
        <p className="font-proximanova-light">
          To complement the craftsmanship of our wardrobes, we utilise
          high-quality hardware, including smooth-gliding mechanisms and durable
          handles.
        </p>
        <h3 className="h3"> Customisable Interior Layouts </h3>{" "}
        <p className="font-proximanova-light">
          Tailor your wardrobe's interior to your specific needs with adjustable
          shelves, partitions and specialised storage solutions.
        </p>
      </section>

      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <h2 className="display-6 font-miracle text-center">
          Tailored Wardrobes To Suit Your Needs
        </h2>
        <div className="hero-section-grid">
          <div
            className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start order-1 order-lg-0"
            style={{ fontSize: "14px" }}
          >
            <p className="font-proximanova-light zeal-digital my-0">
              At Topcab Kitchens & Joinery, we are capable of providing
              different types of wardrobes around Bellevue Hill to fit your home
              and budget requirement. Our range of custom wardrobes is designed
              to cater to a variety of preference and spatial constraints. We
              understand that your home is a reflection of your lifestyle and
              your unique personality. Our team will make sure that your
              wardrobe is not just good looking but maximises your storage.
            </p>
            <p className="font-proximanova-light zeal-digital my-0">
              At Topcab Kitchens & Joinery, we are capable of providing
              different types of wardrobes in Bellevue Hill to fit your home and
              budget requirements. Our range of custom wardrobes is designed to
              cater to a variety of preferences and space constraints:
            </p>
            <ul className="font-proximanova-light d-flex flex-column gap-2 my-0">
              <li>
                <strong>Built-in Wardrobes</strong>: This kind of wardrobe seamlessly integrates
                storage solutions into any room, blending functionality with
                elegance.
              </li>
              <li>
                Walk-in Wardrobes: The high-end wardrobe design creates a
                luxurious dressing area with customisable shelving, hanging
                spaces and drawers for optimal organisation.
              </li>
              <li>
                <strong>Sliding Door Wardrobes</strong>: Perfect for modern interiors where space
                is a constraint, this type of design offers sleek options
                without compromising on storage capacity.
              </li>
            </ul>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="order-0 order-lg-1 d-grid align-items-center justify-content-center">
            <img
              src="/zeal-digital/wardrobe-coogee.jpg"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>

      <section
        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        className="text-white container zeal-digital"
      >
        <div className="text-center pb-5">
          <h2 className="font-miracle display-5 mb-3">
            Benefits Of Choosing Topcab Kitchens & Joinery
          </h2>
          <p className="font-proximanova-light">
            When you hand your wardrobe project to Topcab Kitchens & Joinery,
            you benefit from:
          </p>
        </div>
        <ul>
          {craftsmenData.map((elem) => {
            return (
              <li key={elem.title}>
                <h3> {elem.title} </h3> {elem.desc}
              </li>
            );
          })}
        </ul>
      </section>
      <section
        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        className="text-white container zeal-digital"
      >
        <h2 className="font-miracle mb-3 display-5">
          Our Dedication To Excellence
        </h2>
        <p className="font-proximanova-light">
          We take pride in delivering excellence in every aspect of our service.
        </p>
        <ul className="font-proximanova-light d-flex flex-column gap-2">
          <li>
            Personalised Approach: We approach each project with a touch of
            individuality, as we know each project is unique. Our personalised
            approach ensures that your wardrobe meets or even exceeds your
            expectations.
          </li>
          <li>
            Attention to Detail: From initial consultation to final
            installation, we pay close attention to every detail to ensure a
            seamless and enjoyable experience for all our clients.
          </li>
          <li>
            Respect for Your Space: We treat your home with the utmost respect,
            maintaining cleanliness and professionalism throughout the
            installation.
          </li>
        </ul>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start">
            <h2 className="display-6 font-miracle">Contact Us Today</h2>
            <p className="font-proximanova-light zeal-digital">
              Transform your living space with Topcab Kitchens & Joinery's
              custom wardrobes in Bellevue Hill and across <Link to="/wardrobes-wetherill-park">Wetherill Park</Link> and
              <Link to="/wardrobes-maroubra">Maroubra</Link>. Contact us today by calling{" "}
              <a href="tel:+610296100019">+61 02 96 100 019</a> or{" "}
              <a href="mailto:info@topcabjoinery.com.au">
                info@topcabjoinery.com.au
              </a>{" "}
              to schedule your consultation and discover how we can enhance your
              home with our craftsmanship and dedication to quality. With our
              years of expertise in the joinery industry, let Topcab Kitchens &
              Joinery turn your wardrobe dreams into reality and create a
              functional and aesthetically pleasing space.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <img
              src="/zeal-digital/wardrobe-rose-bay-2.jpg"
              className="bg-dark rounded-3 ms-auto object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
      <section
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        className="container text-white font-proximanova-light"
      >
        <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
          Areas we serve
        </h2>
        <div className="d-grid d-lg-flex flex-wrap gap-4 justify-content-lg-center">
          {areasWeServe?.map((elem) => (
            <Link
              to={elem.href}
              className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
            >
              {elem.title}
            </Link>
          ))}
        </div>
      </section>
      <Form />
    </section>
  );
}
