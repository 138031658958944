import { Link } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function CustomJoineryVaucluse() {
  const areasWeServe = [
    {
      title: "surry hills",
      href: "/custom-joinery-surry-hills",
    },
    {
      title: "northern beaches",
      href: "/custom-joinery-northern-beaches",
    },
    // { title: "vaucluse", href: "/custom-joinery-vaucluse" },
    { title: "eastern suburbs", href: "/custom-joinery-eastern-suburbs" },
    { title: "double bay", href: "/custom-joinery-double-bay" },
    { title: "brookvale", href: "/custom-joinery-brookvale" },
  ];

  const Desktop = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "15rem", paddingBottom: "10rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h1 className="display-6 font-miracle text-white ">
                Elevate Your Home With Topcab Kitchens & Joinery's Bespoke
                Custom Joinery Near Vaucluse
              </h1>
              <p className="font-proximanova-light text-white zeal-digital">
                Topcab Kitchens & Joinery understands the desire to create a
                home that reflects your unique style and functionality needs. As
                specialists in custom joinery around Vaucluse, we bring years of
                experience and exceptional craftsmanship to every project,
                transforming your vision into a reality. Whether you're seeking
                stunning walk-in wardrobes, beautifully-crafted wardrobes in
                Vaucluse or any other custom joinery solution, our team is
                dedicated to exceeding your expectations.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13253.261724374386!2d151.26580079290738!3d-33.85576085220493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ac5769a26495%3A0x5017d681632ce00!2sVaucluse%20NSW%202030%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012437323!5m2!1sen!2sin"
                width="100%"
                height="450"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                  marginLeft: "auto",
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>
        <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <h2 className="display-6 font-miracle text-center">
        The Best Custom Wardrobes In Vaucluse
        </h2>
        <p className="font-proximanova-light zeal-digital my-0">
        While walk-in robes offer a luxurious storage solution, custom wardrobes are a perfect option for those with limited space. Topcab Kitchens & Joinery creates beautiful and functional custom wardrobes in Vaucluse that maximises storage capacity without compromising on style. 
            </p>
        <div className="hero-section-grid">
          <div
            className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start order-1 order-lg-0"
            style={{ fontSize: "14px" }}
          >
            <p className="font-proximanova-light zeal-digital my-0">
              Here's why you should choose Topcab Kitchens & Joinery for your custom wardrobes:
            </p>
            <ul className="font-proximanova-light d-flex flex-column gap-0 my-0">
              <li>
                <strong>Space Optimization</strong>: We design custom wardrobes that utilise the full height and depth of your available space, ensuring you have ample storage for all your belongings.
              </li>
              <li>
                <strong>Walk-in Wardrobes</strong>: We offer a wide range of door styles, finishes and internal configurations to personalise your wardrobe and suit your specific needs. From hinged doors to sliding doors and mirrored finishes, we have something for every taste.
              </li>
              <li>
                <strong>Seamless Integration</strong>: Our custom wardrobes seamlessly integrate into your existing bedroom design, creating a cohesive and aesthetically-pleasing look.
              </li>
            </ul>
            <h3 className="h3 font-miracle">
            Benefits Of Custom Wardrobes
        </h3>
            <p className="font-proximanova-light zeal-digital my-0">
              At Topcab Kitchens & Joinery, we are capable of providing
              different types of wardrobes in Bellevue Hill to fit your home and
              budget requirements. Our range of custom wardrobes is designed to
              cater to a variety of preferences and space constraints:
            </p>
            <ul className="font-proximanova-light d-flex flex-column gap-0 my-0">
              <li>
                <strong>Increased Storage</strong>: Custom wardrobes provide a practical and stylish storage solution for your clothes, shoes and accessories.
              </li>
              <li>
                <strong>Improved Organisation</strong>: The custom layout allows you to personalise the organisation to fit your needs, making it easier to find what you're looking for.
              </li>
              <li>
                <strong>Enhanced Aesthetics</strong>: A well-designed custom wardrobe can elevate the overall look and feel of your bedroom.
              </li>
              <li>
                <strong>Space-Saving Design</strong>: Custom wardrobes optimise unused space, making the most of your bedroom layout.
              </li>
            </ul>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="order-0 order-lg-1 d-grid align-items-center justify-content-center">
            <img
              src="/zeal-digital/wardrobe-coogee.jpg"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
        <section className="container text-white font-proximanova-light zeal-digital">
          <h2 className="font-miracle mb-5 display-6">
            Get The Finest Custom Walk In Robes In Vaucluse
          </h2>
          <p>
            Walk-in robes are a dream come true for many homeowners, offering a
            luxurious and organised space for your wardrobe. Here at{" "}
            <Link to="https://topcabjoinery.com.au/">
              Topcab Kitchens & Joinery
            </Link>
            , we design and build stunning custom walk-in robes in Vaucluse that
            cater to your specific requirements. Here's what sets our walk-in
            robes apart:
          </p>
          <ul>
            <li>
              Maximised Storage: We create clever storage solutions that utilise
              every inch of space efficiently. From hanging rails and shelving
              to drawers, shoe racks and pull-out compartments, we ensure you
              have dedicated storage for all your clothing and accessories.
            </li>
            <li>
              Personalised Design Elements: Whether you prefer a sleek and
              modern aesthetic or a classic and timeless design, we'll
              incorporate your style preferences into the walk-in robe's design.
              We offer a wide range of finishes, hardware and lighting options
              to create a space that reflects your personality.
            </li>
            <li>
              Enhanced Organisation: Our custom walk-in robes in Vaucluse go
              beyond just storage. We can incorporate compartments and dividers
              to categorise your belongings, making it easier to find what you
              need and maintain a clutter-free space.
            </li>
          </ul>
          <h3> Benefits Of A Custom Walk-In Robe</h3>{" "}
          <ul>
            <li>
              Increased Storage: Walk-in robes offer significantly more storage
              space compared to traditional wardrobes, allowing you to keep your
              clothes and accessories organised and easily accessible.
            </li>
            <li>
              Improved Functionality: A well-designed walk-in robe can
              streamline your morning routine by providing a designated space
              for all your clothing needs.
            </li>
            <li>
              Enhanced Luxury: A walk-in robe adds a touch of luxury and
              sophistication to your home, creating a spa-like retreat within
              your bedroom.
            </li>
            <li>
              Boosted Home Value: Custom joinery like walk-in robes, can
              significantly increase the value of your property in Vaucluse, {" "}
              <Link to="https://topcabjoinery.com.au/custom-joinery-double-bay">
                Double Bay
              </Link>{" "}
              or the{" "}
              <Link to="https://topcabjoinery.com.au/custom-joinery-northern-beaches">
                Northern Beaches
              </Link>
              .
            </li>
          </ul>
        </section>

        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="">
              <img
                src="/zeal-digital/kitchen.jpg"
                className="bg-dark rounded-3"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "1/1",
                }}
              />
            </div>
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h2 className="display-6 font-miracle">
                The Topcab Kitchens & Joinery Difference
              </h2>
              <ul className="font-proximanova-light">
                <li>
                  Unwavering Dedication to Quality: We use only the finest
                  materials and employ meticulous construction techniques to
                  ensure your custom joinery at your Vaucluse home is not just
                  beautiful, but also built to last.{" "}
                </li>
                <li>
                  Collaborative Design Process: We support candid dialogue and
                  teamwork all the way through the design process. Our
                  experienced joiners will work closely with you to understand
                  your specific needs and preferences, translating them into
                  stunning and practical joinery solutions.
                </li>
                <li>
                  Attention to Detail: We give close attention to every detail,
                  starting with the first design consultation and ending with
                  the final installation. Your project will be approached from
                  every angle, ensuring a smooth and perfect final product.
                </li>
                <li>
                  Unmatched Expertise: Our team of passionate joiners possesses
                  extensive knowledge and expertise in creating bespoke joinery
                  solutions. We stay up-to-date on the latest trends and
                  technologies, ensuring your project is both stylish and
                  functional.
                </li>
              </ul>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
          </div>
        </section>

        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h2 className="display-6 font-miracle">
                Contact Topcab Kitchens & Joinery Today!
              </h2>
              <p className="font-proximanova-light h5 zeal-digital">
                Schedule a free consultation with one of our joinery experts by
                calling us at <a href="tel:+610296100019">+61 02 96 100 019</a>{" "}
                or email us at{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>{" "}
                to discuss your vision and explore the possibilities for your
                custom joinery project in Vaucluse. We'll work closely with you
                to understand your needs, budget and style preferences and bring
                your dream space to life.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center">
              <img
                src="/zeal-digital/reach-us-2.png"
                className="bg-dark rounded-3 ms-auto"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-flex flex-wrap gap-4 justify-content-center">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  const Mobile = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "10rem", paddingBottom: "3rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 align-items-center align-items-lg-stretch justify-content-center">
              <h1 className="display-6 font-miracle text-white text-center text-lg-start">
                Elevate Your Home With Topcab Kitchens & Joinery's Bespoke
                Custom Joinery Near Vaucluse
              </h1>
              <p className="font-proximanova-light text-white zeal-digital text-center text-lg-start">
                Topcab Kitchens & Joinery understands the desire to create a
                home that reflects your unique style and functionality needs. As
                specialists in custom joinery around Vaucluse, we bring years of
                experience and exceptional craftsmanship to every project,
                transforming your vision into a reality. Whether you're seeking
                stunning walk-in wardrobes, beautifully-crafted wardrobes in
                Vaucluse or any other custom joinery solution, our team is
                dedicated to exceeding your expectations.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-center align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13253.261724374386!2d151.26580079290738!3d-33.85576085220493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ac5769a26495%3A0x5017d681632ce00!2sVaucluse%20NSW%202030%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012437323!5m2!1sen!2sin"
                width="100%"
                height="250"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>
        <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <h2 className="display-6 font-miracle text-center">
        The Best Custom Wardrobes In Vaucluse
        </h2>
        <p className="font-proximanova-light zeal-digital my-2">
        While walk-in robes offer a luxurious storage solution, custom wardrobes are a perfect option for those with limited space. Topcab Kitchens & Joinery creates beautiful and functional custom wardrobes in Vaucluse that maximises storage capacity without compromising on style. 
            </p>
        <div className="hero-section-grid">
          <div
            className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch order-1 order-lg-0"
            style={{ fontSize: "14px" }}
          >
            <p className="font-proximanova-light zeal-digital my-0">
              Here's why you should choose Topcab Kitchens & Joinery for your custom wardrobes:
            </p>
            <ul className="font-proximanova-light d-flex flex-column gap-0 my-0">
              <li>
                <strong>Space Optimization</strong>: We design custom wardrobes that utilise the full height and depth of your available space, ensuring you have ample storage for all your belongings.
              </li>
              <li>
                <strong>Walk-in Wardrobes</strong>: We offer a wide range of door styles, finishes and internal configurations to personalise your wardrobe and suit your specific needs. From hinged doors to sliding doors and mirrored finishes, we have something for every taste.
              </li>
              <li>
                <strong>Seamless Integration</strong>: Our custom wardrobes seamlessly integrate into your existing bedroom design, creating a cohesive and aesthetically-pleasing look.
              </li>
            </ul>
            <h3 className="h3 font-miracle">
            Benefits Of Custom Wardrobes
        </h3>
            <p className="font-proximanova-light zeal-digital my-0">
              At Topcab Kitchens & Joinery, we are capable of providing
              different types of wardrobes in Bellevue Hill to fit your home and
              budget requirements. Our range of custom wardrobes is designed to
              cater to a variety of preferences and space constraints:
            </p>
            <ul className="font-proximanova-light d-flex flex-column gap-0 my-0">
              <li>
                <strong>Increased Storage</strong>: Custom wardrobes provide a practical and stylish storage solution for your clothes, shoes and accessories.
              </li>
              <li>
                <strong>Improved Organisation</strong>: The custom layout allows you to personalise the organisation to fit your needs, making it easier to find what you're looking for.
              </li>
              <li>
                <strong>Enhanced Aesthetics</strong>: A well-designed custom wardrobe can elevate the overall look and feel of your bedroom.
              </li>
              <li>
                <strong>Space-Saving Design</strong>: Custom wardrobes optimise unused space, making the most of your bedroom layout.
              </li>
            </ul>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="order-0 order-lg-1 d-grid align-items-center justify-content-center">
            <img
              src="/zeal-digital/wardrobe-coogee.jpg"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
        <section className="container text-white font-proximanova-light zeal-digital">
          <h2 className="font-miracle mb-5 display-6">
            Get The Finest Custom Walk In Robes In Vaucluse
          </h2>
          <p>
            Walk-in robes are a dream come true for many homeowners, offering a
            luxurious and organised space for your wardrobe. Here at{" "}
            <Link to="https://topcabjoinery.com.au/">
              Topcab Kitchens & Joinery
            </Link>
            , we design and build stunning custom walk-in robes in Vaucluse that
            cater to your specific requirements. Here's what sets our walk-in
            robes apart:
          </p>
          <ul>
            <li>
              Maximised Storage: We create clever storage solutions that utilise
              every inch of space efficiently. From hanging rails and shelving
              to drawers, shoe racks and pull-out compartments, we ensure you
              have dedicated storage for all your clothing and accessories.
            </li>
            <li>
              Personalised Design Elements: Whether you prefer a sleek and
              modern aesthetic or a classic and timeless design, we'll
              incorporate your style preferences into the walk-in robe's design.
              We offer a wide range of finishes, hardware and lighting options
              to create a space that reflects your personality.
            </li>
            <li>
              Enhanced Organisation: Our custom walk-in robes in Vaucluse go
              beyond just storage. We can incorporate compartments and dividers
              to categorise your belongings, making it easier to find what you
              need and maintain a clutter-free space.
            </li>
          </ul>
          <h3> Benefits Of A Custom Walk-In Robe</h3>{" "}
          <ul>
            <li>
              Increased Storage: Walk-in robes offer significantly more storage
              space compared to traditional wardrobes, allowing you to keep your
              clothes and accessories organised and easily accessible.
            </li>
            <li>
              Improved Functionality: A well-designed walk-in robe can
              streamline your morning routine by providing a designated space
              for all your clothing needs.
            </li>
            <li>
              Enhanced Luxury: A walk-in robe adds a touch of luxury and
              sophistication to your home, creating a spa-like retreat within
              your bedroom.
            </li>
            <li>
              Boosted Home Value: Custom joinery like walk-in robes, can
              significantly increase the value of your property in Vaucluse,
              <Link to="https://topcabjoinery.com.au/custom-joinery-double-bay">
                Double Bay
              </Link>{" "}
              or the{" "}
              <Link to="https://topcabjoinery.com.au/custom-joinery-northern-beaches">
                Northern Beaches
              </Link>
              .
            </li>
          </ul>
        </section>

        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="order-0 order-lg-1">
              <img
                src="/zeal-digital/kitchen.jpg"
                className="bg-dark rounded-3 d-block mx-auto"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
            <div className="d-flex flex-column align-items-center align-items-lg-stretch gap-2 justify-content-center order-1 order-lg-0">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                The Topcab Kitchens & Joinery Difference
              </h2>
              <ul>
                <li>
                  Unwavering Dedication to Quality: We use only the finest
                  materials and employ meticulous construction techniques to
                  ensure your custom joinery at your Vaucluse home is not just
                  beautiful, but also built to last.{" "}
                </li>
                <li>
                  Collaborative Design Process: We support candid dialogue and
                  teamwork all the way through the design process. Our
                  experienced joiners will work closely with you to understand
                  your specific needs and preferences, translating them into
                  stunning and practical joinery solutions.
                </li>
                <li>
                  Attention to Detail: We give close attention to every detail,
                  starting with the first design consultation and ending with
                  the final installation. Your project will be approached from
                  every angle, ensuring a smooth and perfect final product.
                </li>
                <li>
                  Unmatched Expertise: Our team of passionate joiners possesses
                  extensive knowledge and expertise in creating bespoke joinery
                  solutions. We stay up-to-date on the latest trends and
                  technologies, ensuring your project is both stylish and
                  functional.
                </li>
              </ul>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
          </div>
        </section>

        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                Contact Topcab Kitchens & Joinery Today!
              </h2>
              <p className="font-proximanova-light h5 zeal-digital text-center text-lg-start">
                Schedule a free consultation with one of our joinery experts by
                calling us at <a href="tel:+610296100019">+61 02 96 100 019</a>{" "}
                or email us at{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>{" "}
                to discuss your vision and explore the possibilities for your
                custom joinery project in Vaucluse. We'll work closely with you
                to understand your needs, budget and style preferences and bring
                your dream space to life.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid ">
              <img
                src="/zeal-digital/reach-us-2.png"
                className="bg-dark rounded-3 mx-auto d-block"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-grid gap-4 mobile-grid-service-area">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  return useWindowSize() ? <Mobile /> : <Desktop />;
}
