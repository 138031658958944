import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./nav.css";
import useWindowSize from "../../../hooks/useWindowSize.js";
import instaHeaderBlack from "../../../assets/images/logos/instaHeader.png";
import facebookHeaderBlack from "../../../assets/images/logos/facebookHeader.png";
import instaHeaderWhite from "../../../assets/images/logos/instaHeaderWhite.png";
import facebookHeaderWhite from "../../../assets/images/logos/facebookHeaderWhite.png";
import burderWhiteBtn from "../../../assets/images/utils/burger-white.svg";
import burderBlackBtn from "../../../assets/images/utils/burger-black.svg";
import close from "../../../assets/images/utils/close.svg";
import arrowUp from "../../../assets/images/utils/serviceArrowUp.svg";
import arrowDown from "../../../assets/images/utils/serviceArrowDown.svg";

const Navbar = ({ color }) => {
  return (
    <>
      {useWindowSize() ? (
        <NavbarMobile color={color} />
      ) : (
        <NavbarDesktop color={color} />
      )}
    </>
  );
};

const NavbarDesktop = ({ color }) => {
  const [navColor, setNavColor] = useState(color);
  const pathname = useLocation().pathname;

  useEffect(() => {
    setNavColor(color);
  }, [color]);

  const isSelected = (path) => {
    let selectionColor;

    if (navColor === "white") {
      selectionColor = "white-border-bottom";
    } else if (navColor === "black") {
      selectionColor = "black-border-bottom";
    }

    const regex = new RegExp(`^${path}(\/|$)`);
    return regex.test(pathname) ? `nav__selected ${selectionColor}` : "";
  };

  // const underlineColor = navColor === "white" ? "white" : "black";

  return (
    <div className="container">
      <nav className="nav__container overlay">
        <ul>
          <li
            className={`${isSelected("/home") || isSelected("/")} ${navColor}`}
          >
            <a href="/" rel="noreferrer" style={{ color: color }}>
              home
            </a>
          </li>
          <li className={`${isSelected("/portfolio")} ${navColor}`}>
            <a href="/portfolio" style={{ color: color }}>
              portfolio
            </a>
          </li>
          <li className={`${isSelected("/services")} ${navColor}`}>
            <a href="/services" target="" style={{ color: color }}>
              services
            </a>
          </li>
          <li className={`${isSelected("/about-us")} ${navColor}`}>
            <a href="/about-us" target="" style={{ color: color }}>
              about us
            </a>
          </li>
          <li className={`${isSelected("/contact-us")} ${navColor}`}>
            <a href="/contact-us" target="" style={{ color: color }}>
              contact us
            </a>
          </li>
          {/* <li className="nav__search">
            <div id="search-field">
              <input
                type="text"
                placeholder="Search..."
                className="input__hover"
              />
              <a href="#!">
                <FontAwesomeIcon
                  className={`fas ${navColor}`}
                  icon={faSearch}
                />
              </a>
            </div>
          </li> */}
          <div className="social__media">
            <li>
              <div className="social__media__icon">
                <a
                  href="https://www.instagram.com/topcabjoinery"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    src={
                      color === "black" ? instaHeaderBlack : instaHeaderWhite
                    }
                    alt="Instagram"
                  />
                </a>
              </div>
            </li>
            <li>
              <div className="social__media__icon">
                <a
                  href="https://www.facebook.com/topcabjoinery"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    src={
                      color === "black"
                        ? facebookHeaderBlack
                        : facebookHeaderWhite
                    }
                    alt="Facebook"
                  />
                </a>
              </div>
            </li>
            <li className="d-block" style={{marginBottom:"-10px"}}>
              <ap-badge
                href="https://archipro.com.au/professional/topcab-kitchens-and-joinery"
                theme={navColor}
                size="150px"
                transparent
              />
            </li>
          </div>
        </ul>
      </nav>
    </div>
  );
};

const NavbarMobile = ({ color }) => {
  const pathname = useLocation().pathname;
  const [navSelected, setNavSelected] = useState(false);
  const [serviceSelected, setServiceSelected] = useState(false);
  const [services, setServices] = useState([]);
  const [loadingServices, setLoadingServices] = useState(false);
  const { REACT_APP_API_GET_SERVICES } = process.env;
  const isSelected = (path) => {
    const regex = new RegExp(`^${path}(\/|$)`);
    return regex.test(pathname) ? "side__nav__selected" : "";
  };

  useEffect(() => {
    setLoadingServices(true);
    fetch(REACT_APP_API_GET_SERVICES)
      .then((res) => res.json())
      .then((data) => {
        setServices(data);
      })
      .catch((err) => console.log(err));
    setLoadingServices(false);
  }, []);

  return (
    <>
      <button
        class="position-absolute bg-transparent border-0"
        style={{ top: "3rem", right: "5%", zIndex: "100" }}
        id="burger"
        onClick={() => {
          setNavSelected(!navSelected);
        }}
      >
        {navSelected ? (
          <img src={close} alt="close" style={{ zIndex: "10" }} />
        ) : (
          <img
            src={color === "white" ? burderWhiteBtn : burderBlackBtn}
            alt="burger"
          />
        )}
      </button>
      {navSelected && (
        <div className="side-bar">
          <div className="side__nav_container">
            <ul>
              <li className={isSelected("/home") || isSelected("/")}>
                <a href="/">home</a>
              </li>
              <li className={isSelected("/portfolio")}>
                <a href="/portfolio">portfolio</a>
              </li>
              <li className={isSelected("/services")}>
                <div className="services__select">
                  <a href="/services">services</a>
                  <img
                    src={serviceSelected ? arrowDown : arrowUp}
                    alt="arrow"
                    onClick={() => setServiceSelected(!serviceSelected)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                {loadingServices && (
                  <div className="d-flex justify-content-center align-items-center border border-top-0 border-bottom-0 border-start-0 border-end-0 h-100 w-100">
                    <div class="spinner-border black" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
                {serviceSelected && (
                  <div className="services__dropdown py-3">
                    <ul style={{ listStyle: "none" }}>
                      {services.map((service) => (
                        <li>
                          <a
                            href={`/services#${service.urlID}`}
                            className="p text-decoration-none color-black1 text-uppercase font-proximanova-light color-grey"
                            style={{
                              letterSpacing: "1px",
                              lineHeight: "1.5",
                              fontSize: "12px",
                            }}
                          >
                            {service.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
              <li className={isSelected("/about-us")}>
                <a href="/about-us">about us</a>
              </li>
              <li className={isSelected("/contact-us")}>
                <a href="/contact-us">contact us</a>
              </li>
            </ul>
            <div className="d-flex justify-content-start align-items-center gap-3 mt-3 w-100">
              <a
                href="https://www.instagram.com/topcabjoinery"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instaHeaderBlack} alt="Instagram" />
              </a>
              <a
                href="https://www.facebook.com/topcabjoinery"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebookHeaderBlack} alt="Facebook" />
              </a>
              <ap-badge
                href="https://archipro.com.au/professional/topcab-kitchens-and-joinery"
                theme="black"
                size="120px"
                transparent
                style={{ marginBottom: "-10px" }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
