import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import arrowIcon from "../assets/images/utils/arrowRight.svg";
import arrowIconV2 from "../assets/images/utils/arrowRightV2.svg";
import Preloader from "../components/commons/preloader/preloader";
import { convertHeicToJpg } from "../utils/utils";
import useWindowSize from "../hooks/useWindowSize";
import { Container, Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slideshow from "../components/services/slideShow";

function Project() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState({});
  const [selectedSection, setSelectedSection] = useState(1);
  const [sectionImages, setSectionImages] = useState([]);
  const [prevProject, setPrevProject] = useState(null);
  const [nextProject, setNextProject] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const windowSize = useWindowSize();
  const sliderRef = useRef(null);
  const {
    REACT_APP_API_GET_PROJECT_BY_ID,
    REACT_APP_API_GET_CURRENT_PROJECTS,
    REACT_APP_ASSETS_PROJECTS_URL,
  } = process.env;
  // const [carouselOpen, setCarouselOpen] = useState(false);
  // const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const resetState = () => {
    setProject({});
    setSelectedSection(1);
    setSectionImages([]);
    setPrevProject(null);
    setNextProject(null);
    setError(null);
  };

  useEffect(() => {
    const fetchSectionImages = async () => {
      setIsLoadingImages(true);
      try {
        const section = project.sections.find(
          (section) => section.id === selectedSection
        );
        if (isMobile && sliderRef) {
          sliderRef.current.slickGoTo(section.id);
        }
        const images = section.pics;
        // const convertedImages = await Promise.all(
        //   images.map(async (image) => {
        //     if (image.url.toLowerCase().endsWith(".heic")) {
        //       const response = await fetch(image.url);
        //       const blob = await response.blob();
        //       const jpgBlob = await convertHeicToJpg(blob);
        //       return URL.createObjectURL(jpgBlob);
        //     } else {
        //       return image;
        //     }
        //   })
        // );
        setSectionImages(images);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoadingImages(false);
      }
    };

    fetchSectionImages();
  }, [project, selectedSection]);

  const handleImageClick = (index) => {
    // console.log(index);
    // setSelectedImageIndex(index);
    // setCarouselOpen(true);
  };

  useEffect(() => {
    resetState();
    const fetchProjectData = async () => {
      try {
        const projectResponse = await fetch(
          REACT_APP_API_GET_PROJECT_BY_ID + id
        );
        if (!projectResponse.ok) {
          throw new Error("Failed to fetch project");
        }
        const projectData = await projectResponse.json();
        setProject(projectData);
      } catch (error) {
        setError(error.message);
      }
    };

    const fetchProjects = async () => {
      try {
        const projectsResponse = await fetch(
          REACT_APP_API_GET_CURRENT_PROJECTS
        );
        if (!projectsResponse.ok) {
          throw new Error("Failed to fetch projects");
        }
        const projectsData = await projectsResponse.json();
        const currentIndex = projectsData.findIndex(
          (proj) => proj.id === parseInt(id)
        );
        const prevIndex =
          currentIndex === 0 ? projectsData.length - 1 : currentIndex - 1;
        const nextIndex =
          currentIndex === projectsData.length - 1 ? 0 : currentIndex + 1;

        setPrevProject(projectsData[prevIndex]);
        setNextProject(projectsData[nextIndex]);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchProjectData();
    fetchProjects();
  }, [REACT_APP_API_GET_PROJECT_BY_ID, id]);

  useEffect(() => {
    if (windowSize) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  const goToPrevProject = () => {
    if (prevProject) {
      navigate(`/portfolio/${prevProject.id}`);
    }
  };

  const goToNextProject = () => {
    if (nextProject) {
      navigate(`/portfolio/${nextProject.id}`);
    }
  };

  if (error) {
    // alert(error);
    // navigate(`/portfolio/`);
  }

  if (!project) {
    navigate("/notFound");
  }

  function handleTabClick(sectionId) {
    setSelectedSection(sectionId);
    if (selectedSection) {
      const mainDiv = document.getElementById("project-header");
      if (mainDiv) {
        mainDiv.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  const handleTabClickMobile = (sectionId, index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index + 1);
      setSelectedSection(sectionId);
      if (selectedSection) {
        const mainDiv = document.getElementById("project-header");
        if (mainDiv) {
          mainDiv.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  };

  const renderTabs = () => {
    const tabWidth = project.sections.length > 5 ? "10rem" : "13rem";
    return (
      project.sections && (
        <div
          className="container d-flex align-items-center justify-content-center border border-top-0 border-start-0 border-end-0 my-5"
          id="navTabs"
        >
          <div className="row align-items-center w-auto justify-content-center">
            <div className="col w-100">
              <ul className="nav nav-underline no-wrap">
                {project.sections
                  .sort((a, b) => a.id - b.id)
                  .map((section) => (
                    <li
                      className="nav-item text-uppercase font-proximanova-light color-black1"
                      style={{
                        cursor: "pointer",
                        width: tabWidth,
                        textAlign: "center",
                      }}
                      key={section.id}
                      onClick={() => {
                        handleTabClick(section.id);
                      }}
                    >
                      <a
                        className={
                          selectedSection === section.id
                            ? "nav-link active font-proximanova-semibold color-black1"
                            : "nav-link color-grey font-proximanova-light"
                        }
                      >
                        {section.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      )
    );
  };

  const renderTabsMobile = () => {
    if (!project.sections || project.sections.length === 0) return null;

    const settings = {
      dots: false,
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "5rem",
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
    };

    return (
      <Container
        className="d-flex align-items-center justify-content-center border border-top-0 border-start-0 border-end-0 my-5"
        id="navTabs"
      >
        <Row className="align-items-center w-100 justify-content-center">
          <Col style={{ width: "100%" }}>
            <div className="slider-container d-flex flex-row align-items-center justify-content-center h-50 w-100">
              <Slider {...settings} className="w-100" ref={sliderRef}>
                {project.sections
                  .sort((a, b) => a.id - b.id)
                  .map((section, index) => (
                    <div
                      className="d-flex align-items-end justify-content-center nav-item text-uppercase font-proximanova-light mx-5 h-100"
                      style={{
                        cursor: "pointer",
                        minWidth: "100px",
                        textAlign: "center",
                        flex: "1 1 auto",
                        maxWidth: "auto",
                      }}
                      key={section.id}
                      onClick={() => handleTabClickMobile(section.id, index)}
                    >
                      <div className="d-flex align-items-end justify-content-center h-100">
                        <a
                          className={
                            selectedSection === section.id
                              ? "nav-link-active link-offset-3 font-proximanova-semibold color-black1"
                              : "nav-link color-grey font-proximanova-light"
                          }
                          style={{
                            textAlign: "center",
                            flexWrap: "wrap",
                            width: "80%",
                          }}
                        >
                          {section.name}
                        </a>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  const renderNavs = () => {
    return (
      <div
        className="container d-flex justify-content-between py-10 pb-3"
        style={{ height: "10%" }}
      >
        <button
          className="btn border-0 bg-transparent d-flex justify-content-center align-items-center z-index-0 gap-3"
          onClick={goToPrevProject}
        >
          <img
            src={arrowIcon}
            alt="previous"
            width={"30px"}
            height={"20px"}
            style={{ rotate: "180deg" }}
          />
          <p
            className="color-grey1 font-proximanova-light text-start d-flex align-items-center justify-content-start"
            style={{ width: "150px", height: "35px", margin: 0 }}
          >
            {prevProject ? prevProject.name.toUpperCase() : "Previous project"}
          </p>
        </button>

        <button
          className="btn border-0 bg-transparent d-flex justify-content-center align-items-center z-index-0 gap-3"
          onClick={goToNextProject}
        >
          <p
            className="color-grey1 font-proximanova-light text-end d-flex align-items-center justify-content-end"
            style={{ width: "150px", height: "35px", margin: 0 }}
          >
            {nextProject ? nextProject.name.toUpperCase() : "Next project"}
          </p>
          <img src={arrowIcon} alt="next" width={"30px"} height={"20px"} />
        </button>
      </div>
    );
  };

  const renderNavsMobile = () => {
    return (
      <Container className="py-10 pb-3">
        <Row className="d-flex justify-content-between align-items-center flex-row">
          <Col xs={4}>
            <button
              className="btn border-0 bg-transparent d-flex justify-content-center align-items-center z-index-0 gap-1"
              onClick={goToPrevProject}
            >
              <img
                src={arrowIconV2}
                alt="previous"
                width={"30px"}
                height={"20px"}
                style={{ rotate: "180deg" }}
              />
              <p
                className="color-grey1 font-proximanova-light text-start m-0"
                style={{
                  display: "flex",
                  alignItems: "center",
                  lineHeight: "1.2",
                  textOverflow: "ellipsis",
                  whiteSpace: "wrap",
                  overflow: "hidden",
                  width: "130px",
                }}
              >
                {prevProject
                  ? prevProject.name.toUpperCase()
                  : "Previous project"}
              </p>
            </button>
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <button
              className="btn border-0 bg-transparent d-flex justify-content-center align-items-center z-index-0 gap-1"
              onClick={goToNextProject}
            >
              <p
                className="color-grey1 font-proximanova-light text-end m-0 "
                style={{
                  display: "flex",
                  alignItems: "center",
                  lineHeight: "1.2",
                  textOverflow: "ellipsis",
                  whiteSpace: "wrap",
                  overflow: "hidden",
                  width: "130px",
                }}
              >
                {nextProject ? nextProject.name.toUpperCase() : "Next project"}
              </p>
              <img
                src={arrowIconV2}
                alt="next"
                width={"30px"}
                height={"20px"}
              />
            </button>
          </Col>
        </Row>
      </Container>
    );
  };

  const renderHeader = () => {
    return (
      <div
        className="container d-flex justify-content-between gap-5 my-5"
        // style={{ maxHeight: "15rem" }}
        id="project-header"
      >
        <Row>
          <Col>
            <h1
              className="h1 color-black1 font-miracle text-uppercase display-4 text-wrap"
              style={{ maxWidth: "500px" }}
            >
              {project.name ? project.name : "No Name"}
            </h1>
          </Col>
          <Col>
            <Row>
              <div className="font-proximanova-light color-black1 p text-start">
                {project.description
                  ? project.description.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        <span dangerouslySetInnerHTML={{ __html: line }} />
                        <br />
                      </React.Fragment>
                    ))
                  : "No Description"}
              </div>
              {project["description-2"] && (
                <div className="font-proximanova-semibold color-black1 p text-start mt-3">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: project["description-2"],
                    }}
                  />
                </div>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  const renderHeaderMobile = () => {
    return (
      <Container className="my-5 px-4">
        <h1
          className="h1 color-black1 font-miracle text-uppercase display-4 text-wrap"
          style={{
            fontWeight: "bold",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            WebkitLineClamp: "2",
            whiteSpace: "initial",
            maxWidth: "70%",
          }}
        >
          {project.name ? project.name : "No Name"}
        </h1>
        <p className="font-proximanova-light color-black1 p text-start mt-3">
          {project.description
            ? project.description.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  <span dangerouslySetInnerHTML={{ __html: line }} />
                  <br />
                </React.Fragment>
              ))
            : "No Description"}
        </p>
        {project["description-2"] && (
          <p className="font-proximanova-light color-black1 p text-start mt-3">
            <span
              dangerouslySetInnerHTML={{
                __html: project["description-2"],
              }}
            />
          </p>
        )}
      </Container>
    );
  };

  const renderGallery = () => {
    const mainImage = sectionImages.find(
      (image) => image.mainSectionImage === true
    );
    const otherImages = sectionImages.filter(
      (image) => !image.mainSectionImage
    );

    return (
      <div className="container mt-5 h-100">
        {isLoadingImages ? (
          <div className="d-flex justify-content-center align-items-center border border-top-0 border-bottom-0 border-start-0 border-end-0 h-100 w-100">
            <div className="spinner-border black" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-4">
              {mainImage && (
                <img
                  src={REACT_APP_ASSETS_PROJECTS_URL + mainImage.url}
                  alt="mainImage"
                  className="img-fluid"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    display: "block",
                    imageRendering: "auto",
                  }}
                />
              )}
            </div>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2, 1200: 2 }}
            >
              <Masonry gutter="2rem">
                {otherImages.map((image, index) => (
                  <div
                    key={index}
                    style={{ width: "100%", position: "relative" }}
                  >
                    <img
                      src={REACT_APP_ASSETS_PROJECTS_URL + image.url}
                      alt="project"
                      className="img-fluid"
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                        maxWidth: "100%",
                        maxHeight: "100%",
                        display: "block",
                        imageRendering: "auto",
                      }}
                      onClick={() => handleImageClick(index)}
                    />
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </>
        )}
      </div>
    );
  };

  function renderDesktop() {
    return (
      <>
        {project &&
        project.name &&
        project.description &&
        project.sections &&
        sectionImages ? (
          <div id="project-main" style={{ marginTop: "10rem" }}>
            {renderNavs()}
            {renderHeader()}
            <div style={{ marginTop: "5rem" }}>{renderTabs()}</div>
            {renderGallery()}
            {renderTabs()}
          </div>
        ) : (
          <Preloader />
        )}
      </>
    );
  }

  function renderMobile() {
    return (
      <>
        {project &&
        project.name &&
        project.description &&
        project.sections &&
        sectionImages ? (
          <div id="project-main" className="my-5">
            {renderNavsMobile()}
            {renderHeaderMobile()}
            {renderTabsMobile()}
            {renderGallery()}
            <Container>
              <Row className="mt-5">
                <Col className="d-flex justify-content-end">
                  <button
                    id="go-up"
                    className="customBtn"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    style={{ borderColor: "black", color: "black" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.368"
                      height="19.368"
                      viewBox="0 0 19.368 19.368"
                    >
                      <g
                        id="Group_6"
                        data-name="Group 6"
                        transform="translate(0.283 19.368) rotate(-90)"
                      >
                        <path
                          id="Path_312"
                          data-name="Path 312"
                          transform="translate(17.745 9.056)"
                          fill="#000"
                        />
                        <path
                          id="Path_223"
                          data-name="Path 223"
                          d="M13.3,13.7H-.4V0H.4V12.9H13.3Z"
                          transform="translate(9.401 18.802) rotate(-135)"
                          fill="#000"
                        />
                      </g>
                    </svg>
                  </button>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <Preloader />
        )}
      </>
    );
  }

  return isMobile ? renderMobile() : renderDesktop();
}

{
  /* {sectionImages && carouselOpen && (
        <Slideshow
          images={sectionImages}
          initialIndex={selectedImageIndex}
          onClose={() => setCarouselOpen(false)}
        />
      )} */
}

export default Project;
