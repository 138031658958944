import { Link } from "react-router-dom";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function JoineryDoverHeights() {
  const areasWeServe = [
    { title: "bondi", href: "/joinery-bondi" },
    // { title: "dover heights", href: "/joinery-dover-heights" },
  ];
  const craftsmenData = [
    {
      title: "Unwavering Dedication to Quality",
      desc: "We are uncompromising in our pursuit of quality. Every project utilises only the finest materials, meticulously crafted with joinery techniques that stand the test of time. This unwavering dedication ensures your custom cabinets are not just stunning additions to your home but incredibly durable pieces built to last a lifetime.",
    },
    {
      title: "Collaborative Design Process",
      desc: "We believe your vision is the foundation for a perfect outcome. Our experienced cabinet makers across Mona Vale will work hand-in-hand with you to understand your unique design goals, space limitations and personal preferences. We translate your ideas into detailed plans, ensuring the final product seamlessly complements your existing décor and reflects your style.",
    },
    {
      title: "Budget and Timeline",
      desc: "Transparency and clear communication are at the heart of our process. We will work diligently to stay within your established budget and adhere to the agreed-upon timeline. Throughout the project, we'll keep you informed of progress and address any questions you may have, ensuring a smooth and stress-free experience.",
    },
    {
      title: "Exceptional Customer Service",
      desc: "Our dedicated team prioritises your satisfaction. We are readily available to answer your questions, address any concerns you may have and ensure you're completely satisfied with the entire process. From initial consultation to final installation, exceptional customer service is our promise to you.",
    },
    {
      title: "A Range of Joinery Solutions",
      desc: "While custom cabinetry is our specialty, our expertise extends far beyond kitchens. We offer a comprehensive suite of joinery services, including wardrobes, vanities, laundries, luxury TV units and living area solutions. This allows us to create a cohesive and stylish look throughout your entire Mona Vale home, ensuring a unified aesthetic that reflects your taste and elevates your living space.",
    },
  ];

  return (
    <section className="zeal-components">
      <section
        style={{ paddingTop: "15rem", paddingBottom: "5rem" }}
        className="container min-vh-100 d-grid justify-content-center"
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch  text-center text-lg-start">
            <h1 className="display-6 font-miracle text-white ">
              Upgrade Your Space With Topcab Kitchens & Joinery's Bespoke
              Wardrobes In Dover Heights
            </h1>
            <p className="font-proximanova-light text-white zeal-digital">
              <Link to="/">Topcab Kitchens & Joinery</Link> understands the
              importance of maximising storage and creating a functional yet
              stylish haven in your home in Dover Heights. That's why we offer a
              comprehensive range of custom-made wardrobes and joinery across
              Dover Heights, tailored to your unique needs and aesthetic
              preferences.
            </p>
            <p className="font-proximanova-light text-white zeal-digital">
              Whether you're seeking a space-saving solution for a compact
              bedroom or a luxurious walk-in haven, our expert joiners can craft
              the perfect wardrobe to complement your existing décor and
              seamlessly integrate with your lifestyle.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13250.430107203158!2d151.27069514292484!3d-33.87400420590048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ad07781786e5%3A0x5017d681632b4e0!2sDover%20Heights%20NSW%202030%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012494236!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="suburb-map"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
                border: 0,
                marginLeft: "auto",
              }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="container text-white font-proximanova-light zeal-digital">
        <h2 className="font-miracle mb-5 display-6">
          Explore Our Range Of Wardrobe Solutions In Dover Heights:
        </h2>
        <h3>Custom Wardrobes </h3>{" "}
        <p>
          Our signature custom wardrobes are the epitome of flexibility. We work
          closely with you to understand your specific needs and preferences,
          meticulously designing a wardrobe that perfectly complements your
          bedroom layout and storage requirements.
        </p>
        <h3>Walk In Wardrobes</h3>{" "}
        <p>
          Indulge in the ultimate luxury with a bespoke walk-in wardrobe from
          Topcab Kitchens & Joinery. We'll transform a designated space into a
          dream closet, incorporating features like built-in drawers, shoe racks
          and full-length mirrors to create a haven for all your clothing and
          accessories.
        </p>
        <h3>Sliding Door Wardrobes</h3>{" "}
        <p>
          Sliding door wardrobes are an excellent space-saving solution,
          particularly well-suited for smaller bedrooms. We offer a wide range
          of door styles and materials, from classic timber to sleek glass, to
          ensure your wardrobe seamlessly blends with your existing décor.
        </p>
        <h3>Built-In Wardrobes </h3>{" "}
        <p>
          For a seamlessly integrated look, consider built-in wardrobes. We'll
          expertly design these wardrobes to perfectly fit your designated
          space, creating a streamlined and pleasing solution that maximises
          storage capacity without compromising on style.
        </p>
        <h3>Open Wardrobes </h3>{" "}
        <p>
          Embrace a minimalist aesthetic with open wardrobes. This option
          showcases your clothing and accessories, adding a touch of personality
          to your bedroom. We can customise open wardrobes with shelves, drawers
          and hanging rods to ensure functionality remains a priority.
        </p>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="order-0 order-lg-1 d-grid align-items-center justify-content-center">
            <img
              src="/zeal-digital/reach-us-2.png"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
          <div
            className="order-1 order-lg-0 d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start"
            style={{ fontSize: "14px" }}
          >
            <h3 className="font-miracle mb-3">
              Stunning Joinery Around Dover Heights
            </h3>
            <p className="font-proximanova-light">
              At Topcab Kitchens & Joinery, we're your one-stop shop for all
              your needs of joinery in Dover Heights. In addition to wardrobes,
              we specialise in crafting a variety of bespoke joinery solutions,
              including:
            </p>
            <ul className="font-proximanova-light d-flex flex-column gap-0 text-start">
              <li>
                <strong>Storage Solutions</strong>: Streamline your living space with custom-made
                cabinets, drawers and shelving units.
              </li>
              <li>
                <strong>Home Office Joinery</strong>: Create a dedicated workspace with bespoke
                desks, bookshelves and storage solutions.
              </li>
              <li>
                <strong>Entertainment Units</strong>: Design a sleek and stylish
                <Link to="/tv-units">
                  {" "}
                  luxury TV unit
                </Link>
                to house your TV, media consoles and audio-visual equipment.
              </li>
              <li>
                <strong>Bathroom Solutions</strong>: Optimise your bathroom area with
                custom-built cabinets, shelves and other vanities.
              </li>
            </ul>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
        </div>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="order-0 d-grid align-items-center justify-content-center">
            <img
              src="/zeal-digital/walk-in-wardrobes.jpg"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
          <div
            className="order-1 d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start"
            style={{ fontSize: "14px" }}
          >
            <h2 className="font-miracle mb-3">
              Choosing Us Is A Smart Move—Here&apos;s Why
            </h2>
            <ul className="font-proximanova-light d-flex flex-column gap-0 text-start  zeal-digital">
              <li>
                <strong>Experienced Craftsmanship</strong>: Our team of skilled{" "}
                <Link to="/detailed-joinery">detailed joinery</Link> experts
                possess years of experience crafting high-quality joinery
                solutions that are built to last.{" "}
              </li>
              <li>
                <strong>Premium Materials</strong>: We use only the finest materials, ensuring
                your wardrobe is not only aesthetically-pleasing but also
                durable and functional.
              </li>
              <li>
                <strong>Exceptional Service</strong>: We focus on open communication and
                collaboration throughout the entire process, from initial
                consultation to installation.{" "}
              </li>
              <li>
                <strong>Competitive Prices</strong>: We offer the most reasonable prices in the
                market without compromising on quality or service whether you
                are in Dover Heights or anywhere in{" "}
                <Link to="https://topcabjoinery.com.au/joinery-bondi">
                  Bondi
                </Link>
                .
              </li>
            </ul>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
        </div>
      </section>
      <section className="text-white container zeal-digital uni-padding-y">
        <div className="text-center">
          <h2 className="font-miracle display-5 mb-3">
            Unleash Your Design Vision With Custom Wardrobes
          </h2>
          <p className="font-proximanova-light">
            Our passion lies in creating bespoke joinery solutions. Unlike
            generic flat-pack options, custom wardrobes from Topcab Kitchens &
            Joinery allow you to:
          </p>
        </div>
        <ul className="font-proximanova-light">
          <li>
            <strong>Optimise Space</strong>: Gone are the days of wasted nooks and crannies. We
            meticulously design wardrobes to maximise storage capacity,
            utilising every inch of available space. In apartments or houses
            with little space, this is very helpful.
          </li>
          <li>
            <strong>Explore Customisation</strong>: We offer a vast selection of materials,
            finishes and configurations to suit your taste and budget. Choose
            from a spectrum of colours, textures and handle styles to
            personalise your wardrobe and create a cohesive look that reflects
            your personality.
          </li>
          <li>
            <strong>Incorporate Functionality</strong>: We understand that storage needs differ.
            Discuss your specific requirements with our team to integrate
            features like pull-out drawers, adjustable shelving, hanging rods at
            varying heights and dedicated shoe racks, ensuring everything has
            its designated place.
          </li>
          <li>
            <strong>Elevate Your Style</strong>: Our wardrobes are not just functional, they're
            stunning focal points within your bedroom. Whether you prefer a
            sleek, modern aesthetic or a timeless, classic design, we'll guide
            you in selecting materials and finishes that enhance the overall
            ambience.
          </li>
        </ul>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start">
            <h2 className="display-6 font-miracle">
              Contact Us For More Information
            </h2>
            <p className="font-proximanova-light zeal-digital">
              Contact Topcab Kitchens & Joinery today at{" "}
              <a href="tel:+610296100019">+61 02 96 100 019</a> or email us at{" "}
              <a href="mailto:info@topcabjoinery.com.au">
                info@topcabjoinery.com.au
              </a>
              . We'll schedule a free consultation to discuss your specific
              needs and vision. Our team will guide you through the design
              process, ensuring your dream wardrobe becomes a reality. Let's
              create a storage solution that not only optimises space but also
              reflects your unique style and elevates your home.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <img
              src="/zeal-digital/wardrobe-rose-bay-2.jpg"
              className="bg-dark rounded-3 ms-auto object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
      <section
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        className="container text-white font-proximanova-light"
      >
        <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
          Area we serve
        </h2>
        <div className="d-grid d-lg-flex flex-wrap gap-4 justify-content-lg-center">
          {areasWeServe?.map((elem) => (
            <Link
              to={elem.href}
              className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
            >
              {elem.title}
            </Link>
          ))}
        </div>
      </section>
      <Form />
    </section>
  );
}
