import { Link } from "react-router-dom";
import "../../../../src/components/zeal-digital/zeal-digital.css";
import Form from "../../../components/contact-us/form";

export default function DetailedJoinery() {
  const AttractionsData = [
    {
      title: "Consultation and design",
      desc: (
        <p className="font-proximanova-light">
          We first conduct a complete consultation to fully understand your
          needs, preferences and vision for your space. We listen to your ideas
          and then offer some suggestions. After that, our team can create a
          design to give you an idea of how the final result will look.
        </p>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64px"
          height="64px"
          viewBox="0 0 36 36"
        >
          <path
            fill="currentColor"
            d="M9 17.41V27h9.59l-2-2H11v-5.59z"
            className="clr-i-outline clr-i-outline-path-1"
          />
          <path
            fill="currentColor"
            d="M34.87 32.29L32 29.38V32H4v-4.15h2v-1.6H4V19.6h2V18H4v-6.4h2V10H4V4.41l15.94 15.85v-2.82L3.71 1.29A1 1 0 0 0 2 2v31a1 1 0 0 0 1 1h31.16a1 1 0 0 0 .71-1.71"
            className="clr-i-outline clr-i-outline-path-2"
          />
          <path
            fill="currentColor"
            d="M24 30h4a2 2 0 0 0 2-2V8.7l-2.3-4.23a2 2 0 0 0-1.76-1a2 2 0 0 0-1.76 1.08L22 8.72V28a2 2 0 0 0 2 2m0-20.8l1.94-3.77L28 9.21V24h-4Zm0 16.43h4v2.44h-4Z"
            className="clr-i-outline clr-i-outline-path-3"
          />
          <path fill="none" d="M0 0h36v36H0z" />
        </svg>
      ),
    },
    {
      title: "Selection of Materials",
      desc: (
        <p className="font-proximanova-light">
          Selecting the right materials is crucial as it is linked to your
          project’s success. We also assist you in selecting the right materials
          that are fit for the project as well as come under your budget. Our
          premium materials ensure that the detailed joinery will be long
          lasting, beautiful and durable.
        </p>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m9.925 13.277l.779-2.58l-2.05-1.505h2.562L12 6.585l.785 2.607h2.561l-2.056 1.504l.78 2.581L12 11.675zM7 21.462v-6.59q-.95-.935-1.475-2.19Q5 11.43 5 10q0-2.927 2.036-4.963T12 3t4.964 2.036T19 10q0 1.429-.525 2.683T17 14.87v6.59l-5-1.5zM12 16q2.5 0 4.25-1.75T18 10t-1.75-4.25T12 4T7.75 5.75T6 10t1.75 4.25T12 16"
          />
        </svg>
      ),
    },
    {
      title: "Fabrication",
      desc: (
        <p className="font-proximanova-light">
          Our talented artisans use traditional joinery techniques and
          high-grade machinery to bring your ideas to life. Every component is
          constructed with attention to detail, ensuring that the finished
          product meets our high standards. To ensure precision, we conduct
          extensive quality control inspections at every stage of our meticulous
          process.
        </p>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M7.22 19.385q-.585 0-1.037-.383q-.454-.382-.554-.965l-1.287-7.702q-.063-.38.172-.665t.622-.286h13.728q.388 0 .622.286q.235.286.172.664l-1.287 7.702q-.1.584-.553.966q-.454.383-1.037.383zm-1.82-9l1.198 7.48q.039.231.202.376q.164.144.394.144h9.612q.23 0 .394-.145q.164-.144.202-.374l1.198-7.481zm4.6 3.5h4q.213 0 .356-.144q.144-.144.144-.357t-.144-.356t-.356-.144h-4q-.213 0-.356.145t-.144.356t.144.356t.356.143M6 8q-.213 0-.356-.144T5.5 7.499t.144-.356T6 7h12q.213 0 .356.144t.144.357t-.144.356T18 8zm2-2.384q-.213 0-.356-.144T7.5 5.115t.144-.356q.143-.144.356-.144h8q.213 0 .356.144t.144.357t-.144.356t-.356.144zM6.598 18.385h10.804z"
          />
        </svg>
      ),
    },
    {
      title: "Installation",
      desc: (
        <p className="font-proximanova-light">
          The last step in our process is professionally installing your
          joinery. Thanks to the expertise of our installation crew, every
          component is fitted precisely and fastened securely. We take
          considerable care to ensure your home is left as uncluttered and
          enjoyable as possible.
        </p>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M13.274 6.949a1.28 1.28 0 0 0-1.168-1.268V2h-.252v3.681a1.28 1.28 0 0 0-1.168 1.268a1.3 1.3 0 0 0 .074.369h2.44a1.3 1.3 0 0 0 .074-.369m4.397-1.268V2h-.252v3.681a1.28 1.28 0 0 0-1.168 1.268a1.3 1.3 0 0 0 .075.369h2.439a1.3 1.3 0 0 0 .074-.369a1.28 1.28 0 0 0-1.168-1.268M7.634 7.318a1.3 1.3 0 0 0 .075-.369a1.28 1.28 0 0 0-1.168-1.268V2h-.252v3.681a1.28 1.28 0 0 0-1.168 1.268a1.3 1.3 0 0 0 .074.369Zm1.976 5.629h-2.6a.9.9 0 0 0-.914.882v3.483a.88.88 0 0 0 .755.851v2.583h.312v-2.552h2.4v2.552h.312v-2.6a.88.88 0 0 0 .648-.83v-3.487a.9.9 0 0 0-.913-.882m7.996 0H15a.9.9 0 0 0-.913.882v3.483a.88.88 0 0 0 .755.851v2.583h.312v-2.552h2.4v2.552h.311v-2.6a.875.875 0 0 0 .648-.83v-3.487a.9.9 0 0 0-.907-.882M2.598 15.345h.541v5.367h.684v-5.367h2.019v-.865H2.598Zm8.175-.865h3.066v.865h-3.066zm7.997 0v.865h1.443v5.367h.684v-5.367h.505v-.865Z"
          />
        </svg>
      ),
    },
  ];

  const craftsmenData = [
    {
      title: "Quality craftsmanship",
      desc: (
        <p className="font-proximanova-light">
          The Topcab Kitchens & Joinery craftsmen blend modern technology and
          traditional techniques to create quality solutions. Our team carefully
          crafts each piece to enhance your home&apos;s functionality and
          beauty.
        </p>
      ),
      imgRef: "/zeal-digital/tv-unit-1.jpg",
    },
    {
      title: "Custom Designs",
      desc: (
        <p className="font-proximanova-light">
          We create custom designs based on your particular requirements and
          preferences. We construct detailed joinery that flawlessly complements
          your environment.
        </p>
      ),
      imgRef: "/zeal-digital/tv-unit-2.jpg",
    },
    {
      title: "Quality materials",
      desc: (
        <p className="font-proximanova-light">
          We use only the best materials to ensure the beauty and durability of
          our joinery. Our superior hardwoods and premium hardware meet the
          highest standards of quality and sustainability.
        </p>
      ),
      imgRef: "/zeal-digital/detailed-joinery.jpg",
    },
    {
      title: "Comprehensive Services",
      desc: (
        <p className="font-proximanova-light">
          Topcab Kitchens & Joinery offers a broad range of joinery services all
          over Sydney, from{" "}
          <Link to="https://topcabjoinery.com.au/wardrobes-rose-bay">
            wardrobes in Rose Bay
          </Link>{" "}
          and{" "}
          <Link to="https://topcabjoinery.com.au/custom-joinery-eastern-suburbs">
            custom cabinetry in the Eastern Suburbs
          </Link>{" "}
          to{" "}
          <Link to="https://topcabjoinery.com.au/kitchen-renovations-randwick">
            kitchen renovations in Randwick
          </Link>{" "}
          or{" "}
          <Link to="https://topcabjoinery.com.au/custom-joinery-brookvale">
            bathrooms in Brookvale
          </Link>
          . Our careful and proven methods ensure that each project is managed
          with precision and experience for a flawless outcome.
        </p>
      ),
      imgRef: "/zeal-digital/tv-unit-4.jpg",
    },
  ];
  return (
    <section className="zeal-components">
      <section
        style={{ paddingTop: "15rem", paddingBottom: "5rem" }}
        className="text-white container min-vh-100 d-grid align-items-center justify-content-center"
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 align-items-center align-items-md-start justify-content-center">
            <h1 className="display-6 font-miracle text-center text-md-start">
              Trusted And Professional Detailed Joinery From Topcab Kitchens &
              Joinery
            </h1>
            <p className="font-proximanova-light zeal-digital text-center text-md-start">
              Are you looking for a reliable, detailed joinery service anywhere
              in Sydney? Do you want to transform your kitchen spaces, get a new
              wardrobe or do you require any other joinery services? Then choose
              Topcab Kitchens & Joinery, where exceptional craftsmanship meets
              innovative designs. We specialise in offering top-quality detailed
              joinery to transform your home into a beautiful and functional
              environment. No matter what your requirement is, whether it's
              enhancing your bathroom, renovating your{" "}
              <Link to="https://topcabjoinery.com.au/luxury-kitchens">
                luxury kitchen
              </Link>{" "}
              or custom cabinetry like a{" "}
              <Link to="https://topcabjoinery.com.au/tv-units">
                luxury TV unit
              </Link>
              , you can count on our expert team to bring your idea to life.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-2"
            >
              Call Now
            </a>
          </div>
          <div className="">
            <img
              src="/zeal-digital/detailed-joinery-2.jpg"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
      <section
        className="container text-white zeal-digital"
        style={{ marginTop: "5rem", marginBottom: "2rem" }}
      >
        <div className="text-center pb-5">
          <h2 className="font-miracle display-5 mb-3">
            Topcab Joinery&apos;s Dedication To Detailed Joinery
          </h2>
          <p className="font-proximanova-light">
            At Topcab Kitchens & Joinery, we believe that care and attention to
            detail are the key to beautifully-designed interiors. Our dedication
            to impeccable, detailed joinery ensures that every component we make
            is meticulously constructed with care, attention to detail and a
            stringent eye for excellence. We pay close attention to every
            detail, from the first stages of design to the last installation, to
            make sure that the end result not only meets but surpasses your
            expectations.
          </p>
        </div>
      </section>
      {/* <section className="zeal-digital">
        <div className="container">
          <div className="text-white row h-100 gy-4 align-items-stretch">
            <div
              className="col-lg-4 col-md-6 d-grid align-items-center justify-content-center bg-dark rounded-3"
              style={{ minHeight: "200px" }}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h2 className="font-miracle display-5">
                Attractions of A Luxury TV Unit
              </h2>
            </div>
            {AttractionsData.map((elem) => {
              return (
                <div
                  key={elem.title}
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="service-item d-flex flex-column gap-2 justify-content-center position-relative">
                    <div>{elem.icon}</div>
                    <h3 className="font-proximanova-bold">{elem.title}</h3>
                    {elem.desc}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section> */}

      <section className="zeal-digital container text-white">
        <h2 className="font-miracle display-5 mb-4">Our Process</h2>
        {AttractionsData.map((elem) => {
          return (
            <div key={elem.title}>
              <div className="service-item d-flex flex-column gap-2 justify-content-center position-relative">
                <h3 className="font-proximanova-light">{elem.title}</h3>
                {elem.desc}
              </div>
            </div>
          );
        })}
      </section>

      <section
        className="container text-white zeal-digital"
        style={{ marginTop: "5rem", marginBottom: "2rem" }}
      >
        <div className="text-center pb-5">
          <h2 className="font-miracle display-5 mb-3">
            Why choose Topcab Kitchens & Joinery?
          </h2>
        </div>
        <ul>
          {craftsmenData.map((elem) => {
            return (
              <li key={elem.title}>
                <h3> {elem.title} </h3> {elem.desc}
              </li>
            );
          })}
        </ul>
      </section>
      <section
        style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
        className="text-white container d-grid align-items-center justify-content-center"
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 align-items-center align-items-md-start justify-content-center">
            <h2 className="display-6 font-miracle text-center text-md-start">
              Book Us For Your Next Project
            </h2>
            <p className="font-proximanova-light h5 zeal-digital text-center text-md-start">
              Transform your home with our quality detailed joinery service in
              Sydney. For more information about our service, feel free to call
              at <a href="tel:+610296100019">+61 02 96 100 019</a> or email at{" "}
              <a href="mailto:info@topcabjoinery.com.au">
                info@topcabjoinery.com.au
              </a>
              , Topcab Kitchens & Joinery will be happy to help you for your
              next project!
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-2"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <img
              src="/zeal-digital/detailed-joinery.jpg"
              className="bg-dark rounded-3 ms-auto object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
      <Form />
    </section>
  );
}
