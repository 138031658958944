import { Link } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function CustomJoineryDoubleBay() {
  const areasWeServe = [
    {
      title: "surry hills",
      href: "/custom-joinery-surry-hills",
    },
    {
      title: "northern beaches",
      href: "/custom-joinery-northern-beaches",
    },
    { title: "vaucluse", href: "/custom-joinery-vaucluse" },
    { title: "eastern suburbs", href: "/custom-joinery-eastern-suburbs" },
    // { title: "double bay", href: "/custom-joinery-double-bay" },
    { title: "brookvale", href: "/custom-joinery-brookvale" },
  ];

  const Desktop = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "15rem", paddingBottom: "10rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h1 className="display-6 font-miracle text-white ">
                Topcab Kitchens & Joinery — Your Joinery Experts In Double Bay
              </h1>
              <p className="font-proximanova-light text-white zeal-digital">
                Are you looking for joinery in Double Bay to craft stylish and
                sleek wardrobes, bathroom vanity or shop fittings? If yes, then
                choose the experts at <Link to="/">Topcab Joinery</Link>! We are
                the most preferred choice as we specialise in delivering
                exceptional joinery services around the area. We take pride in
                our team, which includes highly-skilled artisans with years of
                experience and a passion for creating beautiful and functional
                spaces to match your requirements. Our team can help you make
                the perfect bathroom vanities, wardrobes or shop fittings in
                Double Bay. Our unmatched creations will enhance your business
                or home's overall aesthetics and utility.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13249.644970502415!2d151.2430956!3d-33.8790611!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12addc14006f53%3A0x5017d681632b4d0!2sDouble%20Bay%20NSW%202028%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012069567!5m2!1sen!2sin"
                width="100%"
                height="450"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                  marginLeft: "auto",
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>

        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="">
              <img
                src="/zeal-digital/detailed-joinery-2.jpg"
                className="bg-dark rounded-3 d-block mx-auto"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
            <div className="d-flex flex-column align-items-center align-items-lg-stretch gap-2 justify-content-center">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                Experts For Bathroom Vanities In Double Bay
              </h2>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
                Transform your bathing zone into a luxurious retreat with our
                custom bathroom vanities in Double Bay. Our joinery experts
                design the vanity by combining elegance and practicality,
                ensuring your bathroom space is functional and stunning. No
                matter what type of bathroom vanity design you have in mind, be
                it classic or contemporary, we can create just the kind you have
                in mind.
              </p>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
                To create a stunning bathroom vanity, we use only high-quality
                materials customised with various countertops, finishes and
                hardware options. We know that every client has unique
                requirements for their bathroom remodelling, so we work closely
                with you to understand your style and requirements to deliver
                the best results.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="text-white container zeal-digital"
        >
          <h2 className="font-miracle mb-3 text-center">
          Reasons To Choose Us For Your Joinery Project
          </h2>
          <ul className="font-proximanova-light d-flex flex-column gap-2">
          <li><strong>Expert Craftsmanship</strong>: Our experienced and skilled artisans are devoted to providing joinery solutions of the most excellent quality.</li>
<li><strong>Custom Designs</strong>: We communicate closely with you to design unique furniture that meets your needs and sense of style.</li>
<li><strong>Premium Materials</strong>: We only utilise the best materials to ensure durability and lasting beauty.</li>
<li><strong>Attention to Detail</strong>: Every job is finished with the utmost care, ensuring excellent outcomes.</li>
<li><strong>Customer Satisfaction</strong>: We aim to ensure you are delighted with our outstanding joinery service in Double Bay and in the suburbs of Vaucluse and Coogee. </li>
          </ul>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                High-Quality Shop Fittings In Double Bay By Our Experts
              </h2>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
                Do you want your business to stand out from your competitors? If
                so, then we can help you with that because we offer custom shop
                fittings in Double Bay. We know the importance of having a
                functional and inviting environment. Hence, we design our shop
                fittings to reflect your brand's unique identity and enhance the
                customer experience.
              </p>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
                We offer an array of shopfitting solutions personalised as per
                your specific requirements. From custom display cases and
                counters to shelving units, our team works with you to
                understand your requirements and ensure that every detail is
                perfect, from the initial design to the final result. Choose our
                joinery expertise and let us help you create a retail space that
                looks stunning and also drives sales.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid ">
              <img
                src="/zeal-digital/shop-fittings.jpg"
                className="bg-dark rounded-3 mx-auto d-block"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          className="container text-white zeal-digital"
          style={{ marginTop: "5rem", marginBottom: "2rem" }}
        >
          <div className="text-center pb-5">
            <h2 className="font-miracle display-5 mb-3 ">
              Stylish And Functional Wardrobes In Double Bay
            </h2>
            <p className="font-proximanova-light zeal-digital text-center">
              Do you want to increase the storage space in your bedroom for
              clothes, accessories, etc.?We can help you build the best
              wardrobes in Double Bay within affordable price ranges. Whether
              you need a walk-in closet, a wardrobe with a sliding door or a
              custom design, our expert artisans can design and build the
              wardrobe that best suits your requirements and complements your
              home aesthetics.
            </p>
            <p className="font-proximanova-light zeal-digital text-center">
              As the top joinery outfit around Sydney, we offer high-quality,
              highly functional wardrobes with customisable storage solutions
              for personal items, clothing and accessories. Choose from various
              door styles, interior fittings and finishes, and let us make the
              perfect wardrobe for you.
            </p>
          </div>
        </section>

        <section
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="text-white container zeal-digital"
        >
          <h2 className="font-miracle mb-3">What Our Joinery Process?</h2>
          <p>
            Here's a general idea of how to ensure a high-quality and custom
            joinery:
          </p>
          <ul className="font-proximanova-light d-flex flex-column gap-2">
            <li>
              <strong>Initial Consultation</strong>: We discuss your budget, timeline, ideas and
              needs.
            </li>
            <li>
              <strong>Site Assessment</strong>: We then assess the site by gathering measurements
              and evaluating the space.
            </li>
            <li>
              <strong>Design and Planning</strong>: We design and plan for the joinery process
              based on your requirements.
            </li>
            <li>
              <strong>Material Selection</strong>: Choose the best materials with expert advice.
              We gather the best materials to create high-quality joinery
              whether it is <Link to="/tv-units">luxury TV units</Link>, wardrobes, bathroom vanities, etc.
            </li>
            <li>
              <strong>Fabrication</strong>: We begin crafting the furniture with the utmost
              precision and ensure it is completed in time and under budget.
            </li>
            <li>
            <strong>Pre-Installation Inspection
            </strong>: Verify quality standards.</li>
            <li>
              <strong>Installation</strong>: We verify the quality standards of the final product
              and then get it installed securely and accurately.
            </li>
            <li>
              <strong>Final Inspection</strong>: We conduct a final inspection to ensure the
              final result meets our high quality standards and satisfies you.
            </li>
          </ul>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                Reach Us Anytime
              </h2>
              <p className="font-proximanova-light h5 zeal-digital text-center text-lg-start">
                Are you ready to transform your space with high-quality joinery
                solutions? Then choose Topcab Kitchens & Joinery for a
                world-class service. Contact us today to find out more about our
                services by calling us at
                <a href="tel:+610296100019">+61 02 96 100 019</a> or email us at{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>{" "}
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid ">
              <img
                src="/zeal-digital/reach-us-2.png"
                className="bg-dark rounded-3 mx-auto d-block"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-flex flex-wrap gap-4 justify-content-center">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  const Mobile = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "10rem", paddingBottom: "3rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 align-items-center align-items-lg-stretch justify-content-center">
              <h1 className="display-6 font-miracle text-white text-center text-lg-start">
                Topcab Kitchens & Joinery — Your Joinery Experts In Double Bay
              </h1>
              <p className="font-proximanova-light text-white zeal-digital text-center text-lg-start">
                Are you looking for joinery in Double Bay to craft stylish and
                sleek wardrobes, bathroom vanity or shop fittings? If yes, then
                choose the experts at <Link to="/">Topcab Joinery</Link>! We are
                the most preferred choice as we specialise in delivering
                exceptional joinery services around the area. We take pride in
                our team, which includes highly-skilled artisans with years of
                experience and a passion for creating beautiful and functional
                spaces to match your requirements. Our team can help you make
                the perfect bathroom vanities, wardrobes or shop fittings in
                Double Bay. Our unmatched creations will enhance your business
                or home's overall aesthetics and utility.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-center align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13249.644970502415!2d151.2430956!3d-33.8790611!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12addc14006f53%3A0x5017d681632b4d0!2sDouble%20Bay%20NSW%202028%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012069567!5m2!1sen!2sin"
                width="100%"
                height="250"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>

        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="order-0 order-lg-1">
              <img
                src="/zeal-digital/bathroom-vanities.jpg"
                className="bg-dark rounded-3 d-block mx-auto"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
            <div className="d-flex flex-column align-items-center align-items-lg-stretch gap-2 justify-content-center order-1 order-lg-0">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                Experts For Bathroom Vanities In Double Bay
              </h2>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
                Transform your bathing zone into a luxurious retreat with our
                custom bathroom vanities in Double Bay. Our joinery experts
                design the vanity by combining elegance and practicality,
                ensuring your bathroom space is functional and stunning. No
                matter what type of bathroom vanity design you have in mind, be
                it classic or contemporary, we can create just the kind you have
                in mind.
              </p>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
                To create a stunning bathroom vanity, we use only high-quality
                materials customised with various countertops, finishes and
                hardware options. We know that every client has unique
                requirements for their bathroom remodelling, so we work closely
                with you to understand your style and requirements to deliver
                the best results.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="text-white container zeal-digital"
        >
          <h2 className="font-miracle mb-3 text-center">
          Reasons To Choose Us For Your Joinery Project
          </h2>
          <ul className="font-proximanova-light d-flex flex-column gap-2">
          <li><strong>Expert Craftsmanship</strong>: Our experienced and skilled artisans are devoted to providing joinery solutions of the most excellent quality.</li>
<li><strong>Custom Designs</strong>: We communicate closely with you to design unique furniture that meets your needs and sense of style.</li>
<li><strong>Premium Materials</strong>: We only utilise the best materials to ensure durability and lasting beauty.</li>
<li><strong>Attention to Detail</strong>: Every job is finished with the utmost care, ensuring excellent outcomes.</li>
<li><strong>Customer Satisfaction</strong>: We aim to ensure you are delighted with our outstanding joinery service in Double Bay and in the suburbs of Vaucluse and Coogee. </li>
          </ul>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                High-Quality Shop Fittings In Double Bay By Our Experts
              </h2>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
                Do you want your business to stand out from your competitors? If
                so, then we can help you with that because we offer custom shop
                fittings in Double Bay. We know the importance of having a
                functional and inviting environment. Hence, we design our shop
                fittings to reflect your brand's unique identity and enhance the
                customer experience.
              </p>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
                We offer an array of shopfitting solutions personalised as per
                your specific requirements. From custom display cases and
                counters to shelving units, our team works with you to
                understand your requirements and ensure that every detail is
                perfect, from the initial design to the final result. Choose our
                joinery expertise and let us help you create a retail space that
                looks stunning and also drives sales.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid ">
              <img
                src="/zeal-digital/shop-fittings.jpg"
                className="bg-dark rounded-3 mx-auto d-block"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          className="container text-white zeal-digital"
          style={{ marginTop: "5rem", marginBottom: "2rem" }}
        >
          <div className="text-center pb-5">
            <h2 className="font-miracle display-5 mb-3">
              Stylish And Functional Wardrobes In Double Bay
            </h2>
            <p className="font-proximanova-light zeal-digital text-center">
              Do you want to increase the storage space in your bedroom for
              clothes, accessories, etc.?We can help you build the best
              wardrobes in Double Bay within affordable price ranges. Whether
              you need a walk-in closet, a wardrobe with a sliding door or a
              custom design, our expert artisans can design and build the
              wardrobe that best suits your requirements and complements your
              home aesthetics.
            </p>
            <p className="font-proximanova-light zeal-digital text-center">
              As the top joinery outfit around Sydney, we offer high-quality,
              highly functional wardrobes with customisable storage solutions
              for personal items, clothing and accessories. Choose from various
              door styles, interior fittings and finishes, and let us make the
              perfect wardrobe for you.
            </p>
          </div>
        </section>

        <section
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="text-white container zeal-digital"
        >
          <h2 className="font-miracle mb-3">What Our Joinery Process?</h2>
          <p>
            Here's a general idea of how to ensure a high-quality and custom
            joinery:
          </p>
          <ul className="font-proximanova-light d-flex flex-column gap-2">
          <li>
              <strong>Initial Consultation</strong>: We discuss your budget, timeline, ideas and
              needs.
            </li>
            <li>
              <strong>Site Assessment</strong>: We then assess the site by gathering measurements
              and evaluating the space.
            </li>
            <li>
              <strong>Design and Planning</strong>: We design and plan for the joinery process
              based on your requirements.
            </li>
            <li>
              <strong>Material Selection</strong>: Choose the best materials with expert advice.
              We gather the best materials to create high-quality joinery
              whether it is <Link to="/tv-units">luxury TV units</Link>, wardrobes, bathroom vanities, etc.
            </li>
            <li>
              <strong>Fabrication</strong>: We begin crafting the furniture with the utmost
              precision and ensure it is completed in time and under budget.
            </li>
            <li>
            <strong>Pre-Installation Inspection
            </strong>: Verify quality standards.</li>
            <li>
              <strong>Installation</strong>: We verify the quality standards of the final product
              and then get it installed securely and accurately.
            </li>
            <li>
              <strong>Final Inspection</strong>: We conduct a final inspection to ensure the
              final result meets our high quality standards and satisfies you.
            </li>
          </ul>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                Reach Us Anytime
              </h2>
              <p className="font-proximanova-light h5 zeal-digital text-center text-lg-start">
                Are you ready to transform your space with high-quality joinery
                solutions? Then choose Topcab Kitchens & Joinery for a
                world-class service. Contact us today to find out more about our
                services by calling us at
                <a href="tel:+610296100019">+61 02 96 100 019</a> or email us at{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>{" "}
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid ">
              <img
                src="/zeal-digital/reach-us-2.png"
                className="bg-dark rounded-3 mx-auto d-block"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-grid gap-4 mobile-grid-service-area">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  return useWindowSize() ? <Mobile /> : <Desktop />;
}
